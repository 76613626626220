export const getImg = (curationInfo, stepList) => {
  const bottomList = [];
  let carStepList = null;
  let storeSteplist = null;
  // 차량은 6단계, 매장은 7단계
  stepList.length === 6 ? (carStepList = stepList) : (storeSteplist = stepList);

  if (curationInfo.spaceType === 'Car') {
    // 차량
    // 이용 목적
    if (carStepList[0]) {
      for (const i in curationInfo.carPurpose) {
        if (curationInfo.carPurpose[i] === '출근') {
          const cp1 = '/media/img/carpurpose/go_to_work.png';
          bottomList.push(cp1);
        } else if (curationInfo.carPurpose[i] === '퇴근') {
          const cp2 = '/media/img/carpurpose/leave_work.png';
          bottomList.push(cp2);
        } else if (curationInfo.carPurpose[i] === '근거리 드라이브') {
          const cp3 = '/media/img/carpurpose/shortdrive.png';
          bottomList.push(cp3);
        } else if (curationInfo.carPurpose[i] === '장거리 운행') {
          const cp3 = '/media/img/carpurpose/longdrive.png';
          bottomList.push(cp3);
        } else if (curationInfo.carPurpose[i] === '업무중') {
          const cp3 = '/media/img/carpurpose/working.png';
          bottomList.push(cp3);
        } else if (curationInfo.carPurpose[i] === '일상생활') {
          const cp3 = '/media/img/carpurpose/everydaylife.png';
          bottomList.push(cp3);
        } else if (curationInfo.carPurpose[i] === '여행') {
          const cp3 = '/media/img/carpurpose/travel.png';
          bottomList.push(cp3);
        } else {
          const cp4 = '/media/img/unsplash_JYl_VbBdobw.png';
          bottomList.push(cp4);
        }
      }
    }
  } else {
    // 매장
    // 색상
    if (storeSteplist[0]) {
      //
    }

    // 고객층
    if (storeSteplist[1]) {
      for (const i in curationInfo.target) {
        if (curationInfo.target[i] === '유아/아동') {
          const st1 = '/media/img/unsplash_XIFuymJydII.png';
          bottomList.push(st1);
        } else if (curationInfo.target[i] === '청소년') {
          const st2 = '/media/img/image 1715.png';
          bottomList.push(st2);
        } else if (curationInfo.target[i] === '청년') {
          const st3 = '/media/img/unsplash_yk-CgvlrK1g.png';
          bottomList.push(st3);
        } else if (curationInfo.target[i] === '중장년') {
          const st4 = '/media/img/unsplash_6anudmpILw4.png';
          bottomList.push(st4);
        } else {
          const st5 = '/media/img/unsplash_yRmmm3t1oX8.png';
          bottomList.push(st5);
        }
      }
    }
  }

  // 국내곡 비율
  if ((carStepList != null && carStepList[2]) || (storeSteplist != null && storeSteplist[3])) {
    if (curationInfo.domesticRate === '0') {
      const df1 = `/media/img/curation/img_per0.svg`;
      bottomList.push(df1);
    } else if (curationInfo.domesticRate === '10') {
      const df1 = `/media/img/curation/img_per10.svg`;
      bottomList.push(df1);
    } else if (curationInfo.domesticRate === '20') {
      const df2 = `/media/img/curation/img_per20.svg`;
      bottomList.push(df2);
    } else if (curationInfo.domesticRate === '30') {
      const df3 = `/media/img/curation/img_per30.svg`;
      bottomList.push(df3);
    } else if (curationInfo.domesticRate === '40') {
      const df4 = `/media/img/curation/img_per40.svg`;
      bottomList.push(df4);
    } else if (curationInfo.domesticRate === '50') {
      const df5 = `/media/img/curation/img_per50.svg`;
      bottomList.push(df5);
    } else if (curationInfo.domesticRate === '60') {
      const df6 = `/media/img/curation/img_per60.svg`;
      bottomList.push(df6);
    } else if (curationInfo.domesticRate === '70') {
      const df7 = `/media/img/curation/img_per70.svg`;
      bottomList.push(df7);
    } else {
      const df8 = `/media/img/curation/img_per80.svg`;
      bottomList.push(df8);
    }
  }

  // 장르

  // 시대

  // 선호음악

  return bottomList;
};
