<template>
    <div class="qration_ing modal1">
        <div class="qration_ing_box modal_box">
            <div class="qration_ing_title modal_title">
                <h2>전체보기</h2>
                <div class="modal_close_btn" @click="$emit('closeCurationInfo')">
                    <img src="/media/images/icon_close_24.svg" alt="" />
                </div>
            </div>
            <div>
                <!-- 매장 -->
                <div v-if="curationInfo.mood != null && curationInfo.mood !== ''">
                    <div class="type_title">
                        <h3>공간분위기</h3>
                        <div class="type_span"  v-if="curationInfo.mood != null"><p>{{ this.curationInfo.mood}}</p></div>
                    </div>
                    <div class="type_title">
                        <h3>주요 고객층</h3>
                        <div class="type_span" v-if="curationInfo.target"><p v-for="(item, index) in this.curationInfo.target" :key="index">{{ item }}</p></div>
                    </div>
                    <div class="type_title">
                        <h3>고객 단위</h3>
                        <div class="type_span" v-if="curationInfo.unit"><p v-for="(item, index) in this.curationInfo.unit" :key="index">{{ item }}</p></div>
                    </div>
                </div>
                <!-- 차량 -->
                <div v-if="curationInfo.carPurpose != null && curationInfo.carPurpose.length > 0">
                    <div class="type_title">
                        <h3>차량 이용목적</h3>
                        <div class="type_span" v-if="curationInfo.carPurpose"><p v-for="(item, index) in this.curationInfo.carPurpose" :key="index">{{ item }}</p></div>
                    </div>
                    <div class="type_title">
                        <h3>차량 분위기</h3>
                        <div class="type_span" v-if="curationInfo.carMood != null"><p >{{ this.curationInfo.carMood }}</p></div>
                    </div>
                </div>
                <div class="type_title">
                    <h3>선호 국가</h3>
                    <div class="type_span" v-if="curationInfo.domesticRate">
                        <p>{{ this.curationInfo.domesticRate }}</p>
                    </div>
                </div>
                <div class="type_title">
                    <h3>선호 장르</h3>
                    <div class="type_span" v-if="curationInfo.genre">
                        <p v-for="(item, index) in this.curationInfo.genre" :key="index">{{ item }}</p>
                    </div>
                </div>
                <div class="type_title">
                    <h3>선호 시대</h3>
                    <div class="type_span" v-if="curationInfo.period">
                        <p v-for="(item, index) in this.curationInfo.period" :key="index">{{ item }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'curationInfo',
  props: {
    curationInfo: Object
  }
});
</script>

<style scoped>
  .qration_ing {
    background: rgba(0, 0, 0, 0.7);
  }
</style>
