<template>
    <div class="qration_ing modal">
        <div class="qration_ing_box modal_box">
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <h2>전체보기</h2>
                    <div class="btn_close" @click="$emit('closeCurationInfo')">
                        <img src="/media/images/icon_close_24.svg" />
                    </div>
                </div>
            </div>
            <div class="myspace_content qration add_space">
                <div class="typesM">
                    <!-- 매장 -->
                    <div v-if="curationInfo.color != null && curationInfo.color !== ''">
                        <div class="item">
                            <p class="title">주요 인테리어 색상</p>
                            <div class="content" v-if="curationInfo.color">
                                <p>{{ this.curationInfo.color }}</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="title">주요 인테리어 소재</p>
                            <div class="content" v-if="curationInfo.material">
                                <p v-for="(item, index) in this.curationInfo.material" :key="index">{{ item }}</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="title">고객층</p>
                            <div class="content" v-if="curationInfo.target">
                                <p v-for="(item, index) in this.curationInfo.target" :key="index">{{ item }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- 차량 -->
                    <div v-if="curationInfo.carPurpose != null && curationInfo.carPurpose.length > 0">
                        <div class="item">
                            <p class="title">차량 이용목적</p>
                            <div class="content" v-if="curationInfo.carPurpose">
                                <p v-for="(item, index) in this.curationInfo.carPurpose" :key="index">{{ item }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="item" v-if="curationInfo.domesticRate">
                        <p class="title">선호국가</p>
                        <div class="content">
                            <p>{{ curationInfo.domesticRate }}%</p>
                        </div>
                    </div>
                    <div class="item" v-if="curationInfo.genre && curationInfo.genre.length > 0">
                        <p class="title">장르</p>
                        <div class="content" v-if="curationInfo.genre">
                            <p v-for="(item, index) in this.curationInfo.genre" :key="index">{{ item }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'curationInfoM',
  props: ['curationInfo']
});
</script>

<style scoped>
.qration_ing {
  display: block;
}

.qration {
  padding: 32px 0;
  padding-bottom: 100px;
}

.qration .typesM {
  margin-top: 0;
}

.qration .typesM {
  display: block;
  width: 100%;
}

.qration .typesM .title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.qration .typesM .content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.qration .typesM .content p {
  padding: 10px 12px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 0 8px 8px 0;
}

.qration .typesM .item {
  margin-bottom: 24px;
}

@media all and (max-width: 500px) {
  .add_space {
    height: 660px;
  }
}
</style>
