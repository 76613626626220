<template>
    <div class="wrap">
        <CancelModal v-if="cancelStatus" />
        <CancelCheckModal v-if="cancelCheckStatus" :isRegistCard="isRegistCard" :spaceInfo="spaceInfo"
                          @closeCancel="cancelCheckStatus = false" @showCancel="cancelStatus = true" />
        <div class="space_pc">
            <CurationInfo :curationInfo="curationInfo" v-if="showCurationInfo" @closeCurationInfo="showCurationInfo = false" />
            <section class="main myspace h_100 w_940">
                <div class="main_box my_space3">
                    <div class="main_box_title">
                        <h1 style="margin-bottom: 0px">스페이스 등록</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div class="myspace_content_form qration">
                        <div class="form_text">
                            <h3 style="margin-bottom: 0px">
                                선호하시는 음악의 분위기를 3가지 이상 알려주세요.
                            </h3>
                        </div>
                        <div class="pagnation">
                            <span></span>
                            <span style="width:89%"></span>
                        </div>
                        <div class="three_type fade-up">
                            <div class="music_emotion" style="margin: 0px">
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[0] }" @click="selectEm(1)">
                                        <img src="/media/img/curation_emotion_1.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="1" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>차분한</p>
                                </div>
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[1] }" @click="selectEm(2)">
                                        <img src="/media/img/curation_emotion_2.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="2" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>편안한</p>
                                </div>
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[2] }" @click="selectEm(3)">
                                        <img src="/media/img/curation_emotion_3.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="3" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>활기찬</p>
                                </div>
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[3] }" @click="selectEm(4)">
                                        <img src="/media/img/curation_emotion_4.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="4" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>신나는</p>
                                </div>
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[4] }" @click="selectEm(5)">
                                        <img src="/media/img/curation_emotion_5.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="5" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>슬픈</p>
                                </div>
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[5] }" @click="selectEm(6)">
                                        <img src="/media/img/curation_emotion_6.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="6" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>쓸쓸한</p>
                                </div>
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[6] }" @click="selectEm(7)">
                                        <img src="/media/img/curation_emotion_7.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="7" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>독특한</p>
                                </div>
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[7] }" @click="selectEm(8)">
                                        <img src="/media/img/curation_emotion_8.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="8" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>파워풀한</p>
                                </div>
                                <div class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[8] }" @click="selectEm(9)">
                                        <img src="/media/img/curation_emotion_9.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="9" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>흥분되는</p>
                                </div>
                            </div>
                        </div>
                        <div class="form_bottom space_1">
                            <div class="form_bottom_box">
                                <div>
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                                         @click="showCurationInfo = true">
                                        <path d="M18.636 16.828L13.686 21.778L12.272 20.364L18.636 14L25 20.364L23.586 21.778L18.636 16.828Z"
                                              fill="white" />
                                    </svg>
                                </div>
                                <div class="space_bottom_list" v-if="bottomList.length < 13">
                                    <div v-for="(item, index) in bottomList.slice().reverse()" :key="index"
                                         :style="'z-index: ' + (bottomList.length - index) + ';'">
                                        <img :src="item">
                                    </div>
                                </div>
                                <div class="space_bottom_list space_bottom_list_else" v-else>
                                    <div style="z-index: 12;">
                                        <img :src="bottomList[bottomList.length - 1]">
                                    </div>
                                    <div style="z-index: 11;">
                                        <img :src="bottomList[bottomList.length - 2]">
                                    </div>
                                    <div style="z-index: 10;">
                                        <img :src="bottomList[bottomList.length - 3]">
                                    </div>
                                    <div style="z-index: 9;">
                                        <img :src="bottomList[bottomList.length - 4]">
                                    </div>
                                    <div style="z-index: 8;">
                                        <img :src="bottomList[bottomList.length - 5]">
                                    </div>
                                    <div style="z-index: 7;">
                                        <img :src="bottomList[bottomList.length - 6]">
                                    </div>
                                    <div style="z-index: 6;">
                                        <img :src="bottomList[bottomList.length - 7]">
                                    </div>
                                    <div style="z-index: 5;">
                                        <img :src="bottomList[bottomList.length - 8]">
                                    </div>
                                    <div style="z-index: 4;">
                                        <img :src="bottomList[bottomList.length - 9]">
                                    </div>
                                    <div style="z-index: 3;">
                                        <img :src="bottomList[bottomList.length - 10]">
                                    </div>
                                    <div style="z-index: 2;">
                                        <img :src="bottomList[bottomList.length - 11]">
                                    </div>
                                    <div style="z-index: 1;">
                                        <img :src="bottomList[bottomList.length - 12]">
                                    </div>
                                    <div>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                <div>
                                    <button id="prev_btn" style="text-align: center" @click="clickPre()">이전</button>
                                    <button id="next_btn" style="text-align: center" v-if="emotion.emotionNo.length > 2 && !btnDisabled"
                                            @click="updateCuration">다음</button>
                                    <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>다음</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo h_100">
            <CurationInfoM :curationInfo="curationInfo" v-if="showCurationInfoM"
                           @closeCurationInfo="showCurationInfoM = false" />
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                    </a>
                    <h2>스페이스 등록</h2>
                    <div class="btn_close" @click="linkMySpace()">
                        <img src="/media/images/icon_close_24.svg">
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width:89%"></span>
                </div>
            </div>
            <section class="main registration up_span_main">
                <div class="main_box">
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 style="margin-bottom:0px;">선호하시는 음악의 분위기를 3가지 이상 알려주세요.</h3>
                        </div>
                        <div class="mo_music_emotion">
                            <ul style="padding-left:0px;">
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[0] }" @click="selectEm(1)">
                                        <img src="/media/img/curation_emotion_1.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="1" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>차분한</p>
                                </li>
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[1] }" @click="selectEm(2)">
                                        <img src="/media/img/curation_emotion_2.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="2" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>편안한</p>
                                </li>
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[2] }" @click="selectEm(3)">
                                        <img src="/media/img/curation_emotion_3.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="3" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>활기찬</p>
                                </li>
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[3] }" @click="selectEm(4)">
                                        <img src="/media/img/curation_emotion_4.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="4" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>신나는</p>
                                </li>
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[4] }" @click="selectEm(5)">
                                        <img src="/media/img/curation_emotion_5.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="5" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>슬픈</p>
                                </li>
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[5] }" @click="selectEm(6)">
                                        <img src="/media/img/curation_emotion_6.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="6" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>쓸쓸한</p>
                                </li>
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[6] }" @click="selectEm(7)">
                                        <img src="/media/img/curation_emotion_7.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="7" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>독특한</p>
                                </li>
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[7] }" @click="selectEm(8)">
                                        <img src="/media/img/curation_emotion_8.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="8" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>파워풀한</p>
                                </li>
                                <li class="emotion_box">
                                    <div class="emotion_box_style" :class="{ active: selectItem[8] }" @click="selectEm(9)">
                                        <img src="/media/img/curation_emotion_9.png" alt="">
                                        <input type="checkbox" v-model="emotion" value="9" hidden />
                                        <div class="select_emotion_style"></div>
                                    </div>
                                    <p>흥분되는</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="types_bottom">
                        <svg @click="showInfo('emotion')" width="14" height="8" viewBox="0 0 14 8" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.3025 2.92566L2.3525 7.87566L0.938504 6.46166L7.3025 0.0976563L13.6665 6.46166L12.2525 7.87566L7.3025 2.92566Z"
                                fill="white" />
                        </svg>
                        <div class="mo_space_bottom_list" v-if="bottomList.length < 8">
                            <div v-for="(item, index) in bottomList.slice().reverse()" :key="index"
                                 :style="'z-index: ' + (bottomList.length - index) + ';'">
                                <img :src="item">
                            </div>
                        </div>
                        <div class="mo_space_bottom_list mo_space_bottom_list_else" v-else>
                            <div style="z-index: 7;">
                                <img :src="bottomList[bottomList.length - 1]">
                            </div>
                            <div style="z-index: 6;">
                                <img :src="bottomList[bottomList.length - 2]">
                            </div>
                            <div style="z-index: 5;">
                                <img :src="bottomList[bottomList.length - 3]">
                            </div>
                            <div style="z-index: 4;">
                                <img :src="bottomList[bottomList.length - 4]">
                            </div>
                            <div style="z-index: 3;">
                                <img :src="bottomList[bottomList.length - 5]">
                            </div>
                            <div style="z-index: 2;">
                                <img :src="bottomList[bottomList.length - 6]">
                            </div>
                            <div style="z-index: 1;">
                                <img :src="bottomList[bottomList.length - 7]">
                            </div>
                            <div style="z-index: -1;">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="form_bottom">
                        <button style="text-align: center" v-if="emotion.emotionNo.length > 2 && !btnDisabled"
                                @click="updateCuration">다음</button>
                        <button class="opacity3" style="text-align: center" v-else>다음</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { getImg } from '@/assets/js/spaceBottom.js';
import CurationInfoM from "@/components/modal/Space/CurationInfoM.vue";
import CurationInfo from "@/components/modal/Space/CurationInfo2.vue";
import CancelCheckModal from "@/components/modal/Space/CancelCheckModal.vue";
import CancelModal from "@/components/modal/Space/CancelModal.vue";
import $ from "jquery";
import axios from "axios";
import VueCookies from "vue-cookies";
import router from "@/router";

export default defineComponent({
  components: {
    CurationInfo,
    CancelModal,
    CancelCheckModal,
    CurationInfoM
  },

  data () {
    return {
      btnDisabled: false,
      isInstalledPlayer: false,
      // 전체 감정 목록
      allList: ['차분한', '편안한', '활기찬', '신나는', '슬픈', '쓸쓸한', '독특한', '파워풀한', '흥분되는'],

      // 선택한 감정 목록
      emotion: { emotionNo: [] },

      // 하단 이미지 - 선택한 큐레이션 목록
      bottomList: [],

      // 카드 등록 여부
      isRegistCard: true,

      // 페이지 닫기 팝업
      cancelCheckStatus: false,
      cancelStatus: false,

      // 큐레이션 상세 팝업
      curationInfo: {
        infoStatus: '',
        infoCategory: '',
        mood: '',
        target: [],
        unit: [],
        carPurpose: [],
        carMood: '',
        domesticRate: '',
        genre: [],
        period: []
      },
      curationList: [],
      showCurationInfo: false,
      showCurationInfoM: false,

      tempList: [],
      selectItem: [],
      filterList: []
    };
  },

  mounted () {
    this.getCurationInfo();
    this.checkRegistCard();
    if (this.$store.state.installablePlayer) this.isInstalledPlayer = true;
  },

  methods: {
    // 큐레이션 정보 불러오기
    getCurationInfo () {
      const spaceId = localStorage.getItem("spaceId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          let stepList = null;
          if (res.data.result.spaceType === 'Car') {
            stepList = [true, true, true, true, true, false];
          } else {
            stepList = [true, true, true, true, true, true, false];
          }
          this.bottomList = getImg(res.data.result, stepList);

          // console.log(res.data.result);
          this.tempList = res.data.result.emotion;

          if (res.data.result.carPurpose != null) {
            // 차량
            this.curationInfo.carPurpose = res.data.result.carPurpose;
            this.curationInfo.carMood = res.data.result.carMood;
            this.curationInfo.domesticRate = '국내 ' + res.data.result.domesticRate + '%';
            this.curationInfo.genre = res.data.result.genre.filter((genre) => genre !== '인디음악');
            this.curationInfo.period = res.data.result.period;
            this.curationList = [...this.curationInfo.carPurpose, this.curationInfo.carMood, this.curationInfo.domesticRate, ...this.curationInfo.genre, ...this.curationInfo.period];
          } else {
            // 스토어
            this.curationInfo.mood = res.data.result.mood;
            this.curationInfo.target = res.data.result.target;
            this.curationInfo.unit = res.data.result.targetUnit;
            this.curationInfo.domesticRate = '국내 ' + res.data.result.domesticRate + '%';
            this.curationInfo.genre = res.data.result.genre.filter((genre) => genre !== '인디음악');
            this.curationInfo.period = res.data.result.period;
            this.curationList = [this.curationInfo.mood, ...this.curationInfo.target, ...this.curationInfo.unit, this.curationInfo.domesticRate, ...this.curationInfo.genre, ...this.curationInfo.period];
          }

          if (res.data.result.emotion != null) {
            this.selectPer();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    // 카드 정보 불러오기
    checkRegistCard () {
      const userId = VueCookies.get("userId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/product/getMyCardInfo", { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? this.isRegistCard = true : this.isRegistCard = false;
          }
        })
        .catch(e => { console.log(e); });
    },

    selectEm (val) {
      console.log('val', val);
      const number = this.emotion.emotionNo.indexOf(val);
      console.log('num', number);
      if (number > -1) {
        // 이미 선택
        this.emotion.emotionNo.splice(number, 1);
        this.selectItem[val - 1] = false;
      } else {
        this.emotion.emotionNo.push(val);
        this.selectItem[val - 1] = true;
      }

      const list = this.selectItem;
      this.selectItem = [];
      this.selectItem = list;

      console.log('emotion', this.emotion.emotionNo);
      console.log('selectItem', this.selectItem);
    },

    // 감정 선택 이벤트
    selectEmotion (val) {
      const number = this.emotion.emotionNo.indexOf(val);
      console.log('val', val);
      console.log('num', number);
      if (number > -1) {
        // 이미 선택
        this.emotion.emotionNo.splice(number, 1);
        this.selectItem[val - 1] = false;
      } else {
        this.emotion.emotionNo.push(val);
        this.selectItem[val - 1] = true;
      }

      const list = this.selectItem;
      this.selectItem = [];
      this.selectItem = list;

      console.log('emotion', this.emotion.emotionNo);
      console.log('selectItem', this.selectItem);
    },

    // 모바일 감정 선택 이벤트
    selectEmotionM () {
      $(document).off("click").on("click", ".century ul li", (e) => {
        $(e.currentTarget).children('div').toggle();
        // period 값 넣고 빼기
        var emotionList = this.emotion.emotionNo;
        var emotion = parseInt(e.currentTarget.querySelector('input').value);

        var found = emotionList.find(el => el === emotion);
        if (found != null) { // 선택한 값이 있을때
          var filtered = emotionList.filter((el) => el !== emotion);
          this.emotion.emotionNo = filtered;
        } else { // 선택한 값이 없을때
          this.emotion.emotionNo.push(emotion);
        }
      });
    },

    // 큐레이션 상세 팝업 열기
    showInfo (index) {
      this.curationInfo.infoStatus = index;
      this.curationInfo.infoCategory = 'insert';
      this.showCurationInfoM = true;
    },

    // 큐레이션 감정 값 등록 / 수정
    async updateCuration () {
      this.btnDisabled = true;
      const emotion = (this.emotion.emotionNo.sort((a, b) => { return a - b; })).toString();
      const spaceId = localStorage.getItem("spaceId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      try {
        const { data: curationInfoData } = await this.updateCurationInfo(spaceId, emotion, headers);
        const { resultCd: curationInfoResultCd } = curationInfoData;
        if (curationInfoResultCd === '0000') {
          const { data: curationTempResult } = await this.updateCuraitonTemp(spaceId, emotion, headers);
          const { resultCd: curationTempResultCd } = curationTempResult;
          if (curationTempResultCd === '0000') {
            const { data: preferMusicData } = await this.updateRandomPreferedMusic(spaceId);
            const { resultCd: preferMusicResult } = preferMusicData;
            if (preferMusicResult === '0000') {
              this.isInstalledPlayer ? router.push({ name: "SpaceInsertSelectChannel" }) : router.push({ name: "SpaceInsertStore5" });
            } else {
              this.failUpdateCuration();
            }
          } else {
            this.failUpdateCuration();
          }
        } else {
          this.failUpdateCuration();
        }
      } catch (error) {
        this.failUpdateCuration();
      } finally {
        this.btnDisabled = false;
      }
    },
    failUpdateCuration () {
      alert("선호음악 저장에 실패하였습니다.");
    },
    /**
     * @description 큐레이션 정보 분위기 저장
     * @param {*} curationInfo 큐레이션정보
     * @param {*} headers http 헤더
     * @author CHOI DAE GEON
     */
    async updateCurationInfo (spaceId, emotion, headers) {
      return await axios.post("/api/profile/updateCurationInfo", { spaceId, emotion }, { headers });
    },
    /**
     * @description 큐레이션 정보 임시저장
     * @param {*} curationInfo 큐레이션정보
     * @param {*} headers http 헤더
     * @author CHOI DAE GEON
     */
    async updateCuraitonTemp (spaceId, emotion, headers) {
      return await axios.post("/api/profile/updateCurationTemp", { spaceId, emotion }, { headers });
    },
    /**
   * @description 선호음악 랜덤저장
   * @param {*} spaceId 스페이스ID
   * @author CHOI DAE GEON
   */
    async updateRandomPreferedMusic (spaceId) {
      return await axios.post("/api/profile/updateRandomPreferMusic", { spaceId });
    },

    // 선택했던 큐레이션 표시
    selectPer () {
      var allList = this.allList;
      for (var i in allList) {
        this.selectItem[i] = this.tempList.indexOf(allList[i]) > -1;

        var filter = (allList.indexOf(this.tempList[i])) + 1;
        if (filter > 0) {
          this.filterList.push(filter);
        }
      }
      this.emotion.emotionNo = this.filterList;
    },

    // 등록 그만두기 클릭 시
    linkMySpace () {
      const spaceId = localStorage.getItem("spaceId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/checkSubscribe", { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            // 등록중인 스페이스의 spacePayAt
            if (res.data.result === 'S') {
              this.cancelCheckStatus = true;
            } else {
              router.push({ name: "Player" });
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    // 뒤로가기
    clickPre () {
      history.back();
    }
  }
});
</script>
<style scoped>
/* p, button, div, h2, .notice_txt {
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  } */
.types_bottom>svg {
  height: 15px;
}

.main {
  overflow: auto;
}

.music_emotion {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  column-gap: 3%;
}

.emotion_box {
  position: relative;
  width: 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  background: none !important;
  transform: none !important;
}

.emotion_box .emotion_box_style {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: none !important;
  background: none !important;
  cursor: pointer;
}

.emotion_box .emotion_box_style img {
  width: 100%;
  height: 100%;
}

.emotion_box .emotion_box_style .select_emotion_style {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: none;
  background: url('/media/img/prefer_music.svg'), linear-gradient(180deg, rgba(255, 45, 85, .6), rgba(255, 45, 85, .6));
  box-shadow: 0 0 0 1px #ff2d55;
  background-repeat: no-repeat;
  background-position: 50%;
}

.emotion_box .emotion_box_style.active .select_emotion_style {
  display: block;
}

.emotion_box p {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  color: #fff;
}

@media all and (max-width: 1023) {
  .music_emotion {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 3%;
  }
}

@media all and (max-width: 500px) {
  .mo_music_emotion {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 50px;
  }

  .mo_music_emotion ul {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    -moz-column-gap: 6%;
    column-gap: 6%;
  }

  .myspace_content_form {
    overflow: auto;
  }

  .emotion_box {
    width: 26vw !important;
    height: 26vw !important;
  }

  .emotion_box p {
    top: auto !important;
    font-size: 14px;
    font-weight: 500;
  }

  .emotion_box .emotion_box_style .select_emotion_style {
    width: 26vw !important;
    height: 26vw !important;
  }
}

@media all and (max-width: 376px) {
  .mo_music_emotion ul li p {
    font-size: 13px;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
