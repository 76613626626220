<template>
    <div>
        <div class="single-file-alert3 alert" v-if="!isRegistCard && this.$store.state.trialStatus">
            <div class="single-file-box3 alert_box">
                <h4>거의 다왔어요!<br />그래도 그만하시겠어요?</h4>
                <div class="single-file-btn3">
                    <p id="color-text" @click="$emit('closeCancel')">그렇다면 계속 할래요</p>
                    <p @click="handleUpdateCuration()" style="font-size: 13px">나중에 할래요</p>
                </div>
            </div>
        </div>
        <div class="single-file-alert alert" v-else>
            <div class="single-file-box alert_box">
                <h3>등록중인 스페이스를 저장할까요?</h3>
                <div class="single-file-btn">
                    <p @click="linkMySpace()">아니오</p>
                    <p id="color-text" @click="handleUpdateCuration()">네</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import axios from 'axios';
import { updateCurationAllTemp, updateCurationInfoAllTemp, setSpaceColor, setSpaceMaterial } from '@/service/api/profileApi';
export default {
  name: 'cnacelCheckModal',
  emits: ['deletspace'],
  props: {
    spaceInfo: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    },
    isRegistCard: {
      type: Boolean,
      required: false,
      default: false
    },
    curationinfo: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    isColor: {
      type: String,
      required: false,
      default: 'N'
    },
    colorValue: {
      type: Number,
      required: false,
      default: null
    },
    isMaterial: {
      type: String,
      required: false,
      default: 'N'
    },
    materialValue: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    handleError (err, methodname = '') {
      console.log('error : ', err);
      this.$emit('closeCancel');
      if (err.response) {
        const { data } = err.response;
        const { resultCd, resultMsg } = data;
        if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
          this.$emit('deletspace', resultMsg);
          return false;
        }
      }
      console.log(`${methodname} error : `, err);
    },
    handleUpdateCuration () {
      if (this.isColor === 'Y') {
        // 색상값 적용
        const spaceId = localStorage.getItem('spaceId');
        setSpaceColor(spaceId, this.colorValue).finally(() => {
          this.$emit('closeCancel');
          this.$emit('showCancel');
        });
      } else if (this.isMaterial === 'Y') {
        // 소재값 적용
        const spaceId = localStorage.getItem('spaceId');

        setSpaceMaterial(spaceId, this.materialValue).finally(() => {
          this.$emit('closeCancel');
          this.$emit('showCancel');
        });
      } else {
        this.updateSpaceInfo();
      }
    },
    async updateSpaceInfo () {
      // 매장 정보 수정
      if (this.spaceInfo != null) {
        var aToken = VueCookies.get('aToken');
        var headers = { 'X-AUTH-TOKEN': aToken };
        var spaceInfo = this.spaceInfo;
        VueCookies.remove('spaceNm'); // 쿠키 스페이스명 삭제
        axios
          .post('/api/profile/updateSpaceInfo', spaceInfo, { headers })
          .then(() => {
            this.$emit('closeCancel');
            this.$emit('showCancel');
          })
          .catch((err) => this.handleError(err, 'updateSpaceInfo'));
      } else {
        await updateCurationInfoAllTemp(this.curationinfo);
        updateCurationAllTemp(this.curationinfo)
          .then((res) => {
            const { resultCd } = res.data;
            if (resultCd === '0000') {
              this.$emit('closeCancel');
              this.$emit('showCancel');
            }
          })
          .catch((err) => this.handleError(err, 'updateCurationAllTemp'));
      }
    },
    linkMySpace () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/deleteMySpaceInfo', { spaceId }, { headers })
        .then((res) => {
          // console.log(res.data);
          this.$emit('closeAddSpace');
          this.$emit('closeCancel');
          this.$router.push('/myspace');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
/* 1줄 모달 */
.single-file-box3 {
  box-shadow: 0px 10px 20px rgb(0 0 0 / 80%);
  padding: 24px 24px 8px 24px;
  width: 360px;
}

.single-file-box3 h4 {
  padding-bottom: 24px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  text-align: left;
}

.single-file-btn3 {
  justify-content: center;
  align-items: center;
}

.single-file-btn3 p {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  line-height: 60px;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
}

.single-file-btn3 #color-text {
  color: #ff2d55;
  font-weight: 600;
  background: #ff2d55;
  color: #fff;
  border-radius: 8px;
  height: 48px;
  font-size: 15px;
  line-height: 48px;
}

.single-file-check3 p {
  width: 100%;
  height: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff2d55;
  font-size: 16px;
  cursor: pointer;
}

.single-file-box3 .single-file-content3 {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  padding: 0 5% 5%;
}

@media all and (max-width: 500px) {
  .single-file-box3 {
    box-shadow: 0px 10px 20px rgb(0 0 0 / 80%);
    padding: 24px 20px 8px 20px;
    width: 320px;
  }

  .single-file-box3 h4 {
    padding-bottom: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: left;
  }

  .single-file-btn3 {
    justify-content: center;
    align-items: center;
  }

  .single-file-btn3 p {
    font-size: 15px;
  }

  .single-file-btn3 #color-text {
    font-size: 16px;
  }

  .single-file-check3 p {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff2d55;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
